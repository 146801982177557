import { createApp } from 'vue';
import VueLazyload from 'vue-lazyload';
import App from './App.vue';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';
import router from './router';

const loadimage = require('./assets/giphy.gif');
const errorimage = require('./assets/error.jpg');

createApp(App)
  .use(router)
  .use(VueLazyload, {
    preLoad: 1.3,
    error: errorimage,
    loading: loadimage,
    attempt: 3,
  })
  .mount('#app');
