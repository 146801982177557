<template>
  <Bar isSigned='true'/>
  <component :is="component" @change-component="changeComponent" v-bind:albumId="albumName"/>
</template>

<script>
import { defineAsyncComponent } from 'vue';

const Bar = defineAsyncComponent(() => import('../components/dashboard/NavBar.vue'));
const List = defineAsyncComponent(() => import('../components/dashboard/crud/read.vue'));
const Update = defineAsyncComponent(() => import('../components/dashboard/crud/update.vue'));
const Create = defineAsyncComponent(() => import('../components/dashboard/crud/create.vue'));
const Inbox = defineAsyncComponent(() => import('../components/dashboard/Inbox.vue'));
const Configs = defineAsyncComponent(() => import('../components/dashboard/Configs.vue'));
const About = defineAsyncComponent(() => import('../components/dashboard/AboutInfo.vue'));

export default {
  name: 'dashBoard',
  components: {
    Bar,
    List,
    Update,
    Create,
    Inbox,
    Configs,
    About,
  },
  data: () => ({
    componentIs: 'list',
    albumName: '',
  }),
  computed: {
    component() {
      switch (this.componentIs) {
        case 'list':
          return List;
        case 'create':
          return Create;
        case 'update':
          return Update;
        case 'inbox':
          return Inbox;
        case 'configs':
          return Configs;
        case 'about':
          return About;
        default:
          return undefined;
      }
    },
  },
  methods: {
    changeComponent(payload) {
      this.componentIs = payload.component;
      this.albumName = payload.name;
    },
  },
};
</script>
