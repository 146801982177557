<template>
  <Bar />
  <div class="container">
    <div class="row justify-content-center align-items-end">
      <div class="col-4">
        <form @submit.prevent="login" id="loginForm">
          <h1 class="display-1 heading-text text-capitalize">Log<span> in</span></h1>
          <div class="alert alert-danger" role="alert" v-if="errorData.msg">
            {{ errorData.msg }}
          </div>
          <div class="mb-3">
            <label for="email" class="form-label display-6 heading-text text-capitalize">
              <span>Email address</span>
            <input
              class="form-control"
              type="text"
              name="email"
              placeholder="email@adress.com"
            />
          </label>
          </div>
          <div class="mb-3">
            <label for="password" class="form-label display-6 heading-text text-capitalize">
              <span>Password</span>
            <input
              class="form-control"
              type="password"
              name="password"
              placeholder="****"
            />
          </label>
          </div>
          <button type="submit" class="btn btn-danger btn-lg">Submit</button>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { defineAsyncComponent } from 'vue';
import * as api from '@/api/api';

const Bar = defineAsyncComponent(() => import('../components/dashboard/NavBar.vue'));

export default {
  name: 'LoginPage',
  components: {
    Bar,
  },
  data() {
    return {
      errorData: {
        code: '',
        msg: '',
      },
      email: '',
      password: '',
    };
  },
  methods: {
    login(submitEvent) {
      this.email = submitEvent.target.elements.email.value;
      this.password = submitEvent.target.elements.password.value;
      if (this.email && this.password) {
        this.errorData = {};
        api.getAuth(this.email, this.password)
          .then(() => {
            this.errorData = {};
            document.getElementById('loginForm').reset();
            this.$router.push('/dashboard');
          })
          .catch((error) => {
            console.log(error);
            this.errorData.code = error.code;
            this.errorData.msg = error.message;
          });
      } else {
        this.errorData.msg = 'Please fill in the form!';
      }
    },
  },
};
</script>
