import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/Index.vue';
import dashboardView from '../views/Dashboard.vue';
import Login from '../views/Login.vue';
import NotFound from '../components/Error.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: dashboardView,
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/:pathMatch(.*)*',
    component: NotFound,
    props: {
      msg: '404 Not Found',
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
