<template>
  <div class="container text-center">
    <div class="row justify-content-center align-items-end">
      <div class="col-4">
        <h1 class="display-1 text-danger">Error</h1>
        <p>{{ msg }}</p>
        <a href="/" type="submit" class="btn btn-danger">Back to Home page</a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ErrorComponent',
  props: {
    msg: String,
  },
};
</script>
