<template>
  <div v-if="errorState.state">
    <ErrorComponent :msg="errorState.errorDefaultMsg"/>
  </div>
  <div v-else>
      <nav class="container-fluid-nav navbar navbar-expand-lg bg-body-tertiary">
      <div class="container-fluid">
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false"
        aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li class="nav-item" v-for="item in albums" v-bind:key="item.name">
              <button class="nav-link" :id="item.ID" :class="{ active: meta.name == item.name }"
              v-on:click="fetchData(item.ID)">{{item.name}}</button>
            </li>
          </ul>
            <div class="text-right">
              <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                <li class="nav-item">
                  <a href="#"
                    v-on:click="showAboutInfo()"
                    class="nav-link">About Me</a>
                </li>
                <li class="nav-item">
                  <a href="#"
                    v-on:click="getContact()"
                    class="nav-link">Contact</a>
                </li>
              </ul>
            </div>
        </div>
      </div>
    </nav>
    <div v-if="showContact">
      <Contact />
    </div>
    <div v-if="showAbout">
      <About v-bind:aboutInfo="aboutInfo" v-if="aboutInfo.name"/>
    </div>
    <div v-else-if="meta">
      <TopButton v-if="showGallery"/>
      <GalleryData v-bind:data="meta" v-if="showGallery" />
      <FooterComponent :build="build" />
    </div>
    <div v-else>
      <ErrorComponent :msg="error.errorDefaultMsg"/>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue';
import * as api from '@/api/api';
import buildInfo from '../../buildInfo.json';

const About = defineAsyncComponent(() => import('../components/About.vue'));
const GalleryData = defineAsyncComponent(() => import('../components/Gallery.vue'));
const TopButton = defineAsyncComponent(() => import('../components/TopButton.vue'));
const ErrorComponent = defineAsyncComponent(() => import('../components/Error.vue'));
const Contact = defineAsyncComponent(() => import('../components/Contact.vue'));
const FooterComponent = defineAsyncComponent(() => import('../components/Footer.vue'));

export default {
  name: 'IndexPage',
  components: {
    About,
    GalleryData,
    ErrorComponent,
    TopButton,
    Contact,
    FooterComponent,
  },
  data: () => ({
    showContact: false,
    showAbout: true,
    showGallery: false,
    aboutInfo: {},
    meta: {},
    albums: [],
    errorState: {
      state: false,
      errorDefaultMsg: 'Something went wrong :(',
    },
    build: buildInfo.id,
  }),
  methods: {
    async showAboutInfo() {
      document.title = 'About';
      this.date = await api.getPersonInfo();
      this.aboutInfo = this.date.data;
      this.showContact = false;
      this.showAbout = true;
      this.showGallery = false;
    },
    getContact() {
      this.showContact = true;
      this.showAbout = false;
      this.showGallery = false;
    },
    async fetchData(id) {
      this.showContact = false;
      this.showAbout = false;
      try {
        this.date = await api.getPhotos(id);
        this.meta = this.date.data;
        document.title = this.meta.name;
        this.errorState.state = false;
        this.showGallery = true;
      } catch (ex) {
        console.log(ex);
      }
    },
  },
  async beforeMount() {
    try {
      await this.showAboutInfo();
      const tmpResponse = await api.getAlbums();
      this.albums = tmpResponse.data;
      this.errorState.state = false;
    } catch (ex) {
      console.log(ex);
      this.errorState.state = true;
    }
  },
};
</script>

<style>
body {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.heading-text {
  color: #DA0037;
}
.heading-text span {
  font-weight: 100;
  color: #444444;
}
</style>
